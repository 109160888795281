<cg-toast-popup
  data-cy="application-toast"
  class="[&>div>div]:bg-white [&>div]:top-24"
  [hasRoundBorders]="true"
  (closeClicked)="closeToast()"
>
  <div class="flex gap-4">
    <cg-icon class="h-8 w-8" [content]="infoIcon"></cg-icon>
    <div>
      <cg-headline [type]="'h3'" [content]="'jobApplication.errorToastHeadline' | transloco"></cg-headline>
      <cg-paragraph>{{ message }}</cg-paragraph>
    </div>
  </div>
</cg-toast-popup>
