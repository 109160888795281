import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiService } from "@cg/core/api";
import { FileService } from "@cg/shared";
import { CvFile } from "../interfaces/cv-file.interface";
import { JobApplication } from "../interfaces/job-application.interface";

@Injectable({
  providedIn: "root"
})
export class JobApplicationService {
  public constructor(
    private readonly apiService: ApiService,
    private readonly fileService: FileService
  ) {}

  public sendJobApplication(jobApplication: JobApplication): Observable<void> {
    const formData = this.createFormData(jobApplication);
    return this.apiService.post<void>("jobApplication", "", formData, { setNoContentType: true });
  }

  public createFormData(jobApplication: JobApplication): FormData {
    const formData = new FormData();
    formData.append("application", JSON.stringify(jobApplication.application));
    const files = jobApplication.files.map((cvFile: CvFile) =>
      this.fileService.dataURLtoFile(cvFile.fileAsBase64String, cvFile.fileName)
    );
    files.forEach((file: File) => {
      formData.append("file", file);
    });
    return formData;
  }
}
