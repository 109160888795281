import { Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { JobApplication } from "../interfaces/job-application.interface";
import * as JobApplicationActions from "./job-application.actions";
import * as JobApplicationSelectors from "./job-application.selector";

@Injectable({
  providedIn: "root"
})
export class JobApplicationFacade {
  public errorToastMessage$ = this.store.pipe(select(JobApplicationSelectors.getErrorToastMessage));

  public constructor(private readonly store: Store) {}

  public sendJobApplicationButtonClicked(jobApplication: JobApplication): void {
    this.store.dispatch(JobApplicationActions.sendJobApplicationButtonClicked({ jobApplication }));
  }

  public setErrorToastMessage(message: string): void {
    this.store.dispatch(JobApplicationActions.setErrorToastMessage({ message }));
  }
}
