import { AsyncPipe, DatePipe } from "@angular/common";
import { ChangeDetectionStrategy, Component, DestroyRef, inject, Inject, OnInit } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { of } from "rxjs";
import { take, tap } from "rxjs/operators";
import { SpinnerComponent } from "@cg/spinner";
import { TranslocoPipe, TranslocoService } from "@jsverse/transloco";
import { HeadlineComponent, IconComponent, ParagraphComponent, PictureComponent } from "@cg/core/ui";
import { IS_BROWSER_PLATFORM } from "@cg/core/utils";
import { MetaDataService } from "@cg/html-header";
import { infoYellowIcon } from "@cg/icon";
import { ScrollService } from "@cg/olb/shared";
import { SafeContentPipe } from "@cg/shared";
import { JobApplicationFacade } from "../../+state/job-application.facade";
import { jobApplicationHeaderImage } from "../../models/job-application.model";
import { JobApplicationErrorToastComponent } from "./job-application-error-toast/job-application-error-toast.component";
import { JobApplicationFormularComponent } from "./job-application-formular/job-application-formular.component";

@Component({
  selector: "cg-job-application",
  templateUrl: "./job-application.component.html",
  styleUrls: ["./job-application.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    TranslocoPipe,
    PictureComponent,
    HeadlineComponent,
    ParagraphComponent,
    JobApplicationErrorToastComponent,
    JobApplicationFormularComponent,
    SpinnerComponent,
    SafeContentPipe,
    IconComponent
  ],
  providers: [DatePipe]
})
export class JobApplicationComponent implements OnInit {
  public destroyRef = inject(DestroyRef);
  public errorToastMessage$ = of("");

  public readonly headerImage = jobApplicationHeaderImage;
  protected readonly infoYellowIcon = infoYellowIcon;

  public constructor(
    private readonly jobApplicationFacade: JobApplicationFacade,
    private readonly scrollService: ScrollService,
    private readonly metaDataService: MetaDataService,
    private readonly translocoService: TranslocoService,
    @Inject(IS_BROWSER_PLATFORM) private readonly isBrowser: boolean
  ) {}

  public ngOnInit(): void {
    this.translocoService
      .selectTranslate("jobApplication.metaDataDocumentTitle")
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe((text: string) => {
        this.metaDataService.setDocumentTitle(text);
      });

    if (this.isBrowser) {
      this.scrollService.registerAppScrollContainer(window);
    }

    this.errorToastMessage$ = this.jobApplicationFacade.errorToastMessage$.pipe(
      tap((message: string) => {
        if (message) {
          setTimeout(() => {
            this.jobApplicationFacade.setErrorToastMessage("");
          }, 4000);
          this.scrollService.scrollToPositionOnApp({ top: 0, left: 0 });
        }
      }),
      takeUntilDestroyed(this.destroyRef)
    );
  }
}
