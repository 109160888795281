import { createAction, props } from "@ngrx/store";
import type { JobApplication } from "../interfaces/job-application.interface";

export const sendJobApplicationButtonClicked = createAction(
  "[Job-Application/API] Send Job Application Button Clicked",
  props<{ jobApplication: JobApplication }>()
);

export const jobApplicationDispatchSuccessResultReceived = createAction(
  "[Job-Application/API] Job Application Dispatch Success Result Received"
);

export const setErrorToastMessage = createAction(
  "[Job-Application/API] Set Error Toast Message",
  props<{ message: string }>()
);
