<div class="job-application--container">
  <cg-picture [content]="headerImage"></cg-picture>
  @if (errorToastMessage$ | async; as errorToastMessage) {
    <cg-job-application-error-toast [message]="errorToastMessage"></cg-job-application-error-toast>
  }
  <div class="job-application--formular-content-wrapper">
    <div class="job-application--headline-wrapper">
      <cg-headline class="mb-5" [type]="'h1'" [content]="'jobApplication.headline' | transloco"></cg-headline>
      <div class="flex flex-row items-center gap-2.5">
        <cg-icon class="h-6 w-6" [content]="infoYellowIcon"></cg-icon>
        <cg-paragraph>
          <span [innerHTML]="'jobApplication.subHeadline' | transloco"></span>
        </cg-paragraph>
      </div>
    </div>
    <cg-job-application-formular></cg-job-application-formular>
  </div>
</div>
<cg-spinner></cg-spinner>
