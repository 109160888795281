import { Injectable } from "@angular/core";
import { GoogleTagManagerService } from "@cg/analytics";
import { AddFormControls } from "@cg/core/types";
import { ApplicantForm } from "../interfaces/applicant.form.interface";
import { CvFile } from "../interfaces/cv-file.interface";
import { JobApplicationTrackingEvent } from "../interfaces/job-application-tracking-event.interface";

@Injectable({
  providedIn: "root"
})
export class JobApplicationTrackingService {
  public constructor(private readonly gtmService: GoogleTagManagerService) {}

  public trackSubmitApplicationEvent(isFailed: boolean, filesCount: number): void {
    this.trackEvent({
      eventLabel: isFailed ? "fail" : "success",
      eventAction: "submit-application",
      application: { documents: filesCount }
    });
  }

  public trackDocumentToBigEvent(): void {
    this.trackEvent({ eventAction: "upload-documenttype", eventLabel: "document-too-big" });
  }

  public trackEventsForUploadedFiles(cvFiles: CvFile[]): void {
    cvFiles.forEach((cvFile: CvFile) => {
      this.trackEvent({
        eventAction: this.createFileEventAction(cvFile.fileName),
        eventLabel: cvFile.fileSizeAsString
      });
    });
  }

  public trackEventsOfInvalidFields(controls: AddFormControls<ApplicantForm>): void {
    for (const name in controls) {
      if (controls[name].invalid) {
        this.createMissingTrackEvent(name);
      }
    }
  }

  public trackMissingCv() {
    this.createMissingTrackEvent("cv");
  }

  private createMissingTrackEvent(name: string) {
    this.trackEvent({ eventLabel: "missing-" + name, eventAction: "error" });
  }

  private createFileEventAction(fileName: string): string {
    const nameSplitted = fileName.split(".");
    const dataType = nameSplitted[nameSplitted.length - 1];
    return `upload-${dataType}`;
  }

  private trackEvent(jobApplicationTrackingEvent: JobApplicationTrackingEvent): void {
    let data: JobApplicationTrackingEvent = {
      eventCategory: "jobs",
      eventAction: jobApplicationTrackingEvent.eventAction,
      eventLabel: jobApplicationTrackingEvent.eventLabel
    };
    if (jobApplicationTrackingEvent.application) {
      data = { ...data, application: jobApplicationTrackingEvent.application };
    }
    this.gtmService.trackEvent(data);
  }
}
