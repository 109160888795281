import { provideEffects } from "@ngrx/effects";
import { provideState } from "@ngrx/store";
import { JobApplicationEffects } from "./+state/job-application.effect";
import * as fromJobApplication from "./+state/job-application.reducer";
import { JOB_APPLICATION_FEATURE_KEY } from "./+state/job-application.reducer";

export function provideJobApplication() {
  return [
    provideState(JOB_APPLICATION_FEATURE_KEY, fromJobApplication.reducer),
    provideEffects([JobApplicationEffects])
  ];
}
