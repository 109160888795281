import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { TranslocoPipe } from "@jsverse/transloco";
import { HeadlineComponent, IconComponent, ParagraphComponent } from "@cg/core/ui";
import { infoYellowIcon } from "@cg/icon";
import { ToastPopupComponent } from "@cg/shared";
import { JobApplicationFacade } from "../../../+state/job-application.facade";

@Component({
  selector: "cg-job-application-error-toast",
  templateUrl: "./job-application-error-toast.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslocoPipe, ToastPopupComponent, IconComponent, HeadlineComponent, ParagraphComponent]
})
export class JobApplicationErrorToastComponent {
  @Input()
  public message: string;

  public readonly infoIcon = infoYellowIcon;

  public constructor(private readonly jobApplicationFacade: JobApplicationFacade) {}

  public closeToast(): void {
    this.jobApplicationFacade.setErrorToastMessage("");
  }
}
