import { type Action, createReducer, on } from "@ngrx/store";
import * as JobApplicationActions from "./job-application.actions";

export const JOB_APPLICATION_FEATURE_KEY = "jobApplication";

export interface JobApplicationState {
  errorToastMessage: string;
}

export interface JobApplicationPartialState {
  readonly [JOB_APPLICATION_FEATURE_KEY]: JobApplicationState;
}

export const initialState: JobApplicationState = {
  errorToastMessage: null
};

const jobApplicationReducer = createReducer(
  initialState,
  on(JobApplicationActions.setErrorToastMessage, (state: JobApplicationState, action: { message: string }) => ({
    ...state,
    errorToastMessage: action.message
  }))
);

export function reducer(state: JobApplicationState, action: Action): JobApplicationState {
  return jobApplicationReducer(state, action);
}
