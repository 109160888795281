import { AsyncPipe, DatePipe } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, inject, OnInit } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { combineLatest, mergeMap, Observable, of } from "rxjs";
import { filter, map, take, tap } from "rxjs/operators";
import { SpinnerFacade } from "@cg/spinner";
import * as CgValidators from "@cg/validators";
import { TranslocoPipe, TranslocoService } from "@jsverse/transloco";
import { Cta } from "@cg/content-api/typescript-interfaces";
import { AddFormControls } from "@cg/core/types";
import { HeadlineComponent, HeadlineType } from "@cg/core/ui";
import { arrowsIcon } from "@cg/icon";
import { ScrollService } from "@cg/olb/shared";
import {
  CheckboxComponent,
  CheckboxInput,
  CtaComponent,
  CtaVariation,
  DateInputComponent,
  FileInputComponent,
  FileService,
  InputType,
  OptionSelectionItem,
  OptionsSelectionComponent,
  RadioButtonGroup,
  RadioButtonGroupComponent,
  RadioButtonHorizontalGroupComponent,
  TextInput,
  TextInputComponent
} from "@cg/shared";
import { JobApplicationFacade } from "../../../+state/job-application.facade";
import { ApplicantForm } from "../../../interfaces/applicant.form.interface";
import { ApplicationRequest } from "../../../interfaces/application-request.interface";
import { CvFile } from "../../../interfaces/cv-file.interface";
import { JobApplication } from "../../../interfaces/job-application.interface";
import { ApplicantInputs, inputFields } from "../../../models/input-fields.model";
import { JobApplicationTrackingService } from "../../../services/job-application-tracking.service";
import { EmploymentType } from "./type/employment.type";

@Component({
  selector: "cg-job-application-formular",
  templateUrl: "./job-application-formular.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    TranslocoPipe,
    ReactiveFormsModule,
    HeadlineComponent,
    RadioButtonHorizontalGroupComponent,
    RadioButtonGroupComponent,
    TextInputComponent,
    OptionsSelectionComponent,
    DateInputComponent,
    CheckboxComponent,
    CtaComponent,
    FileInputComponent
  ],
  providers: [DatePipe]
})
export class JobApplicationFormularComponent implements OnInit {
  private static readonly FILE_ENDINGS: RegExp[] = [
    /\.doc.*$/i,
    /\.png$/i,
    /\.jpeg$/i,
    /\.jpg$/i,
    /\.jpe$/i,
    /\.pdf$/i
  ];

  public destroyRef = inject(DestroyRef);
  public inputFields: ApplicantInputs = inputFields;
  public form: FormGroup<AddFormControls<ApplicantForm>>;
  public isLoadingSpinnerVisible$ = of(false);

  public readonly headlineType = HeadlineType;

  public readonly minDate = new Date(Date.now());

  public readonly desiredPlaceToWorkPerimeters: OptionSelectionItem[] = [
    { id: "desired-place-to-work-perimeter-20", text: "20 km", value: "20", disabled: false },
    { id: "desired-place-to-work-perimeter-40", text: "40 km", value: "40", disabled: false },
    { id: "desired-place-to-work-perimeter-100", text: "100 km", value: "100", disabled: false }
  ];

  public readonly titleGroup: RadioButtonGroup = {
    controlName: "title",
    buttons: [
      {
        title: "jobApplication.formular.radioButtons.female",
        radio: { id: "no-appointment-insured-female", value: "Frau" }
      },
      {
        title: "jobApplication.formular.radioButtons.male",
        radio: { id: "no-appointment-insured-male", value: "Herr" }
      },
      {
        title: "jobApplication.formular.radioButtons.divers",
        radio: { id: "no-appointment-insured-divers", value: "Divers" }
      }
    ]
  };

  public readonly employmentTypeGroup: RadioButtonGroup = {
    controlName: "employmentType",
    buttons: [
      {
        title: "jobApplication.formular.radioButtons.fullTime",
        radio: { id: "no-appointment-insured-full-time", value: "Vollzeit" }
      },
      {
        title: "jobApplication.formular.radioButtons.partTime",
        radio: { id: "no-appointment-insured-part-time", value: "Teilzeit" }
      },
      {
        title: "jobApplication.formular.radioButtons.miniJob",
        radio: { id: "no-appointment-insured-mini-job", value: "MiniJob" }
      }
    ]
  };

  public privacyPolicy: CheckboxInput;
  public readonly ctaTitleKey = "jobApplication.formular.sendCtaTitle";
  public sendCta: Cta;

  public readonly formRow: {
    input: TextInput;
    formControlName: string;
    inputType?: InputType;
  }[] = [
    { formControlName: "firstName", input: inputFields.firstnameInput },
    { formControlName: "lastName", input: inputFields.lastnameInput },
    { formControlName: "street", input: inputFields.streetInput },
    {
      formControlName: "postalCode",
      input: inputFields.postalCodeInput,
      inputType: InputType.ZIP
    },
    {
      formControlName: "city",
      input: inputFields.cityInput
    },
    {
      formControlName: "email",
      input: inputFields.emailInput,
      inputType: InputType.E_MAIL
    },
    {
      formControlName: "phoneNumber",
      input: inputFields.phoneNumberInput,
      inputType: InputType.NUMBERS_PHONE
    }
  ];
  protected readonly InputType = InputType;

  // eslint-disable-next-line max-params
  public constructor(
    private readonly jobApplicationFacade: JobApplicationFacade,
    private readonly cdr: ChangeDetectorRef,
    private readonly translocoService: TranslocoService,
    private readonly scrollService: ScrollService,
    private readonly spinnerFacade: SpinnerFacade,
    private readonly jobApplicationTrackingService: JobApplicationTrackingService,
    private readonly datePipe: DatePipe,
    private readonly fileService: FileService
  ) {}

  public ngOnInit(): void {
    this.isLoadingSpinnerVisible$ = this.spinnerFacade.visible$;

    this.initSendCta();
    this.initForm();
    this.initPrivacyPolicyInput();
    this.formValueChanges();
  }

  public initSendCta(): void {
    this.translocoService
      .selectTranslate(this.ctaTitleKey)
      .pipe(take(1))
      .subscribe((text: string) => {
        this.sendCta = {
          id: "send-job-application-cta",
          title: text,
          icon: arrowsIcon,
          link: {
            href: null,
            title: text,
            text,
            routerLink: false
          },
          arrowDirection: "right",
          variation: CtaVariation.PRIMARY,
          ngTemplate: "cgCta"
        };
        this.cdr.markForCheck();
      });
  }

  public sendForm(): void {
    this.form.markAllAsTouched();
    this.jobApplicationFacade.setErrorToastMessage("");

    if (this.form.invalid) {
      this.onInvalidForm();
      return;
    }

    const controls = this.form.controls;
    const application: ApplicationRequest = this.buildApplicationRequest(controls);

    this.readFileContentsOf([...controls.cvUpload.value, ...(controls.otherUpload?.value ?? [])])
      .pipe(
        map((cvFile: CvFile[]): JobApplication => this.buildJobApplication(application, cvFile)),
        tap((jobApplication: JobApplication) => this.sendJobApplication(jobApplication)),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  private buildJobApplication(application: ApplicationRequest, cvFile: CvFile[]): JobApplication {
    return {
      application,
      files: cvFile
    };
  }

  private buildApplicationRequest(controls: AddFormControls<ApplicantForm>): ApplicationRequest {
    return {
      title: controls.title.value.trim(),
      firstName: controls.firstName.value.trim(),
      lastName: controls.lastName.value.trim(),
      street: controls.street.value.trim(),
      postalCode: controls.postalCode.value.trim(),
      city: controls.city.value.trim(),
      desiredWorkplace: controls.desiredWorkplace.value.trim(),
      privacyPolicyAccepted: controls.privacyPolicyAccepted.value,
      employmentType: controls.employmentType.value,
      email: controls.email.value.trim(),
      phoneNumber: controls.phoneNumber.value.trim(),
      workplaceRadius: parseInt(controls.workplaceRadius.value, 10),
      possibleStartOfWork: this.datePipe.transform(controls.possibleStartOfWork.value, "YYYY-MM-dd"),
      drivingLicenceB: true
    };
  }

  private onInvalidForm(): void {
    this.scrollService.scrollToFirstError();
    this.jobApplicationTrackingService.trackEventsOfInvalidFields(this.form.controls);

    const { cvUpload, otherUpload } = this.form.controls;

    if (cvUpload.errors?.required) {
      this.jobApplicationTrackingService.trackMissingCv();
    }

    this.jobApplicationTrackingService.trackSubmitApplicationEvent(
      true,
      (cvUpload.value?.length ?? 0) + (otherUpload.value?.length ?? 0)
    );
  }

  private sendJobApplication(jobApplication: JobApplication): void {
    this.jobApplicationTrackingService.trackEventsForUploadedFiles([...jobApplication.files]);
    this.jobApplicationFacade.sendJobApplicationButtonClicked(jobApplication);
  }

  private readFileContentsOf(files: File[]): Observable<CvFile[]> {
    return of(
      files.map((file: File) =>
        this.fileService.readFileAsDataURL$(file).pipe(
          map(
            (contents: string | ArrayBuffer): CvFile => ({
              fileName: file.name,
              fileSize: file.size,
              fileSizeAsString:
                file.size < 1_000_000
                  ? `${Math.floor(file.size / 1_000)} KB`
                  : `${Math.floor(file.size / 1_000_000)} MB`,
              fileAsBase64String: contents as string
            })
          )
        )
      )
    ).pipe(mergeMap((observables: Observable<CvFile>[]) => combineLatest(observables)));
  }

  private initForm(): void {
    const postalCodeValidators = [Validators.required, Validators.pattern(/^[0-9]*$/), Validators.minLength(5)];

    this.form = new FormGroup<AddFormControls<ApplicantForm>>({
      title: new FormControl<string>(null, Validators.required),
      firstName: new FormControl<string>(null, CgValidators.nameValidators()),
      lastName: new FormControl<string>(null, CgValidators.nameValidators()),
      street: new FormControl<string>(null, CgValidators.streetValidators()),
      postalCode: new FormControl<string>(null, postalCodeValidators),
      city: new FormControl<string>(null, CgValidators.cityValidators()),
      desiredWorkplace: new FormControl<string>(null, postalCodeValidators),
      privacyPolicyAccepted: new FormControl<boolean>(null, Validators.required),
      email: new FormControl<string>(null, CgValidators.emailValidators(true)),
      phoneNumber: new FormControl(null, [...CgValidators.phoneValidators(), Validators.minLength(10)]),
      workplaceRadius: new FormControl<string>(null, Validators.required),
      cvUpload: new FormControl<File[]>(null, [
        Validators.required,
        CgValidators.filesMaxSize(10),
        CgValidators.filesMaxCount(5),
        CgValidators.filesValidExtensions(JobApplicationFormularComponent.FILE_ENDINGS)
      ]),
      otherUpload: new FormControl<File[]>(null, [
        CgValidators.filesMaxSize(10),
        CgValidators.filesMaxCount(10),
        CgValidators.filesValidExtensions(JobApplicationFormularComponent.FILE_ENDINGS)
      ]),
      employmentType: new FormControl<EmploymentType>(null, Validators.required),
      possibleStartOfWork: new FormControl<string>(null, Validators.required)
    });
  }

  private initPrivacyPolicyInput(): void {
    this.translocoService
      .selectTranslate("jobApplication.formular.checkbox.privacy.text", {
        privacyUrl: "/datenschutz"
      })
      .pipe(take(1))
      .subscribe((text: string) => {
        this.privacyPolicy = {
          id: "applicant-privacyPolicyAccepted",
          controlName: "privacyPolicyAccepted",
          text,
          style: "big",
          errorMessage: "jobApplication.formular.checkbox.privacy.error"
        };
        this.cdr.markForCheck();
      });
  }

  private formValueChanges(): void {
    this.form.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.jobApplicationFacade.setErrorToastMessage(""));

    const privacyPolicyAcceptedControl = this.form.controls.privacyPolicyAccepted;
    privacyPolicyAcceptedControl.valueChanges
      .pipe(
        filter((value: boolean) => !value && value !== null),
        tap(() => privacyPolicyAcceptedControl.setValue(null)),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }
}
