import { createFeatureSelector, createSelector } from "@ngrx/store";
import type { JobApplicationPartialState, JobApplicationState } from "./job-application.reducer";
import { JOB_APPLICATION_FEATURE_KEY } from "./job-application.reducer";

export const getJobApplicationState = createFeatureSelector<JobApplicationPartialState, JobApplicationState>(
  JOB_APPLICATION_FEATURE_KEY
);

export const getErrorToastMessage = createSelector(getJobApplicationState, (state: JobApplicationState) =>
  state ? state.errorToastMessage : null
);
