import { environment } from "@cg/environments";

export const jobApplicationHeaderImage = {
  sizes: [
    {
      srcset: `${environment.assetPath}/job-application/bwformular_XS.png`,
      media: "xs"
    },
    {
      srcset: `${environment.assetPath}/job-application/bwformular_SM.png`,
      media: "sm"
    },
    {
      srcset: `${environment.assetPath}/job-application/bwformular_XL.png`,
      media: "xl"
    }
  ],
  source: `${environment.assetPath}/job-application/bwformular_XL.png`,
  alt: "Job Application Header",
  ngTemplate: "cgPicture"
};
