<form data-cy="application-form" [formGroup]="form">
  <div class="mb-[1.375rem] d:mb-8">
    <cg-headline
      [type]="headlineType.h3"
      [content]="'jobApplication.formular.contactHeadline' | transloco"
    ></cg-headline>
    <div class="mb-4 flex flex-1 flex-col gap-0 d:flex-row d:gap-4">
      <cg-radio-button-horizontal-group
        formControlName="title"
        class="mt-4"
        [content]="titleGroup"
      ></cg-radio-button-horizontal-group>
    </div>

    <div class="grid grid-cols-1 gap-0 d:grid-cols-2 d:flex-row d:gap-x-4">
      @for (entry of formRow; track entry) {
        <cg-text-input
          class="mb-4"
          [formControlName]="entry.formControlName"
          [content]="entry.input"
          [specificInputType]="entry.inputType"
        ></cg-text-input>
      }
    </div>
  </div>

  <div class="mb-[1.375rem] flex flex-col gap-4 d:mb-8">
    <cg-headline
      [type]="headlineType.h3"
      [content]="'Für welche Art der Anstellung interessierst du dich?'"
    ></cg-headline>
    <cg-radio-button-horizontal-group
      formControlName="employmentType"
      class="[&>div]:flex-col [&>div]:items-start [&>div]:gap-4 m:[&>div]:flex-row m:[&>div]:gap-6"
      [content]="employmentTypeGroup"
    ></cg-radio-button-horizontal-group>
  </div>

  <div class="mb-[1.375rem] d:mb-8">
    <div class="grid grid-cols-1 gap-4 d:grid-cols-2">
      <div class="flex flex-col gap-4">
        <cg-headline
          [type]="headlineType.h3"
          [content]="'jobApplication.formular.placeToWorkHeadline' | transloco"
        ></cg-headline>
        <div class="flex flex-1 flex-col gap-0">
          <cg-text-input
            formControlName="desiredWorkplace"
            class="mb-4"
            [content]="inputFields.desiredWorkplaceInput"
          ></cg-text-input>
          <cg-options-selection
            formControlName="workplaceRadius"
            class="w-full [&>div>select]:mt-0 [&>div>select]:text-base [&>div>select]:font-bold [&>div>select]:text-cg-anthracite-light-30"
            [selectionId]="'applicant-desired-place-to-work-perimeter'"
            [placeholder]="
              'jobApplication.formular.optionSelection.desiredPlaceToWorkPerimeter.placeholder' | transloco
            "
            [errorMessage]="'jobApplication.formular.optionSelection.desiredPlaceToWorkPerimeter.error'"
            [items]="desiredPlaceToWorkPerimeters"
          ></cg-options-selection>
        </div>
      </div>
      <div class="flex flex-col gap-4">
        <cg-headline
          [type]="headlineType.h3"
          [content]="'jobApplication.formular.startToWorkHeadline' | transloco"
        ></cg-headline>
        <cg-date-input
          formControlName="possibleStartOfWork"
          [id]="'applicant-start-date'"
          [minDate]="minDate"
          [placeholder]="'TT.MM.JJJJ*'"
          [errorMessage]="'jobApplication.formular.inputs.possibleStartOfWork.error'"
        ></cg-date-input>
      </div>
    </div>
  </div>

  <div class="mb-[1.375rem] d:mb-8">
    <div class="d:gap-4-of-driver-licence-cv-upload-columns flex flex-1 flex-col gap-0 d:flex-row">
      <div class="flex h-full w-full flex-grow flex-col gap-4">
        <div class="grid grid-cols-1 gap-6 d:grid-cols-2">
          <div class="flex flex-col gap-4">
            <cg-headline
              [type]="headlineType.h3"
              [content]="'jobApplication.formular.cvHeadline' | transloco"
            ></cg-headline>
            <cg-file-input
              data-cy="cv-upload"
              formControlName="cvUpload"
              maxFileCount="5"
              required="true"
              [errorMessageRequired]="'jobApplication.formular.inputs.files.error.required' | transloco"
              [errorMessageMaxFileSize]="
                'jobApplication.formular.inputs.files.error.maxFileSize' | transloco: { megaBytes: 10 }
              "
              [errorMessageMaxFileCount]="
                'jobApplication.formular.inputs.files.error.maxFileCount' | transloco: { count: 5 }
              "
              [errorMessageWrongFileExtension]="'jobApplication.formular.inputs.files.error.fileExtension' | transloco"
              [fileType]="'jobApplication.formular.inputs.files.fileType.cv' | transloco"
              [infoText]="[
                'jobApplication.formular.inputs.files.validFormats' | transloco,
                'jobApplication.formular.inputs.files.maxFiles' | transloco: { count: 5 }
              ]"
            ></cg-file-input>
          </div>
          <div class="flex flex-col gap-4">
            <cg-headline
              [type]="headlineType.h3"
              [content]="'jobApplication.formular.otherFilesHeadline' | transloco"
            ></cg-headline>
            <cg-file-input
              data-cy="other-upload"
              formControlName="otherUpload"
              maxFileCount="10"
              [errorMessageMaxFileSize]="
                'jobApplication.formular.inputs.files.error.maxFileSize' | transloco: { megaBytes: 10 }
              "
              [errorMessageMaxFileCount]="
                'jobApplication.formular.inputs.files.error.maxFileCount' | transloco: { count: 10 }
              "
              [errorMessageWrongFileExtension]="'jobApplication.formular.inputs.files.error.fileExtension' | transloco"
              [fileType]="'jobApplication.formular.inputs.files.fileType.other' | transloco"
              [infoText]="[
                'jobApplication.formular.inputs.files.validFormats' | transloco,
                'jobApplication.formular.inputs.files.maxFiles' | transloco: { count: 10 }
              ]"
            ></cg-file-input>
          </div>
        </div>
      </div>
    </div>
  </div>

  @if (privacyPolicy) {
    <div class="mt-4">
      <cg-checkbox
        data-cy="applicant-privacyPolicyAccepted-wrapper"
        formControlName="privacyPolicyAccepted"
        class="font-roboto-condensed text-cg-anthracite-light-30"
        [content]="privacyPolicy"
      ></cg-checkbox>
    </div>
  }
  @if (sendCta) {
    <div class="mt-6 flex flex-1 flex-col gap-0 d:flex-row d:gap-4">
      <cg-cta
        data-cy="send-job-application-cta"
        class="w-full whitespace-nowrap t:w-fit d:w-max"
        [content]="sendCta"
        [disabled]="isLoadingSpinnerVisible$ | async"
        (clickedFn)="sendForm()"
      ></cg-cta>
      <div class="job-application-formular--input-placeholder"></div>
    </div>
  }
</form>
