import type { TextInput } from "@cg/shared";

export interface ApplicantInputs {
  firstnameInput: TextInput;
  lastnameInput: TextInput;
  streetInput: TextInput;
  streetNumberInput: TextInput;
  postalCodeInput: TextInput;
  cityInput: TextInput;
  emailInput: TextInput;
  phoneNumberInput: TextInput;
  desiredWorkplaceInput: TextInput;
}
export const inputFields: ApplicantInputs = {
  firstnameInput: {
    id: "applicant-firstname",
    controlName: "firstName",
    placeholder: "jobApplication.formular.inputs.firstname.placeholder",
    errorMessage: "jobApplication.formular.inputs.firstname.error"
  },
  lastnameInput: {
    id: "applicant-lastname",
    controlName: "lastName",
    placeholder: "jobApplication.formular.inputs.lastname.placeholder",
    errorMessage: "jobApplication.formular.inputs.lastname.error"
  },
  streetInput: {
    id: "applicant-street",
    controlName: "street",
    placeholder: "jobApplication.formular.inputs.street.placeholder",
    errorMessage: "jobApplication.formular.inputs.street.error"
  },
  streetNumberInput: {
    id: "applicant-streetNumber",
    controlName: "streetNumber",
    placeholder: "jobApplication.formular.inputs.streetNumber.placeholder",
    errorMessage: "jobApplication.formular.inputs.streetNumber.error"
  },
  postalCodeInput: {
    id: "applicant-postalCode",
    controlName: "postalCode",
    placeholder: "jobApplication.formular.inputs.postalCode.placeholder",
    errorMessage: "jobApplication.formular.inputs.postalCode.error"
  },
  cityInput: {
    id: "applicant-city",
    controlName: "city",
    placeholder: "jobApplication.formular.inputs.city.placeholder",
    errorMessage: "jobApplication.formular.inputs.city.error"
  },
  emailInput: {
    id: "applicant-email-address",
    controlName: "email",
    placeholder: "jobApplication.formular.inputs.email.placeholder",
    errorMessage: "jobApplication.formular.inputs.email.error"
  },
  phoneNumberInput: {
    id: "applicant-phoneNumber",
    controlName: "phoneNumber",
    placeholder: "jobApplication.formular.inputs.phoneNumber.placeholder",
    errorMessage: "jobApplication.formular.inputs.phoneNumber.error"
  },
  desiredWorkplaceInput: {
    id: "applicant-desired-work-place",
    controlName: "desiredWorkplace",
    placeholder: "jobApplication.formular.inputs.desiredWorkplace.placeholder",
    errorMessage: "jobApplication.formular.inputs.desiredWorkplace.error"
  }
};
